import React from "react";
import Img, { FluidObject } from "gatsby-image";
import BgImage from "gatsby-background-image";
import { useMediaQuery } from "react-responsive";

import styles from "./home.module.scss";

interface ProjectProps {
  chart: FluidObject;
  bgProjectDesktop: FluidObject;
  bgProjectMobile: FluidObject;
}

export default function Project({
  chart,
  bgProjectDesktop,
  bgProjectMobile,
}: ProjectProps) {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  // Art directing image
  const bgProject = [
    bgProjectMobile,
    {
      ...bgProjectDesktop,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <BgImage Tag="section" fluid={bgProject} className={styles.project}>
      <div className={styles.txt1}>
        {isDesktop ? (
          <p>
            Dalam kurun waktu 10 tahun, Kami telah membantu
            <br />
            lebih dari <span className={styles.highlight}>
              3.923 Project
            </span>{" "}
            pembuatan seragam
          </p>
        ) : (
          <p>
            Dalam kurun waktu 10 tahun, Kami telah membantu lebih dari{" "}
            <span className={styles.highlight}>3.923 Project</span> pembuatan
            seragam
          </p>
        )}
      </div>
      <Img
        fluid={chart}
        alt="project chart"
        className={styles.chart}
        imgStyle={{ objectFit: "contain" }}
      />
      <div className={styles.txt2}>
        {isDesktop ? (
          <p>
            Semakin banyak klien baru yang kami bantu setiap tahunnya.
            <br />
            Dan juga, bertambah pula pelanggan setia yang selalu
            <br />
            terpuaskan dengan seragamnya.
          </p>
        ) : (
          <p>
            Semakin banyak klien baru yang kami bantu setiap tahunnya. Dan juga,
            bertambah pula pelanggan setia yang selalu terpuaskan dengan
            seragamnya.
          </p>
        )}
      </div>
    </BgImage>
  );
}
